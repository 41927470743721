<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="card card-custom gutter-b example example-compact"
          v-if="filter"
        >
          <div class="card-header">
            <!-- <div class="row align-items-center">
              <div class="col-lg-10 col-xl-8"> -->
            <div class="row align-items-center px-0 pa-8">
              <div class="col-md-3 my-2 my-md-0">
                <div class="input-icon">
                  <input
                    type="text"
                    v-model="full_name__icontains"
                    v-debounce:400ms="myFn"
                    class="form-control"
                    placeholder="Kontragent"
                    id="kt_datatable_search_query"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="col-md-3 my-2 my-md-0">
                <div class="input-icon">
                  <input
                    type="text"
                    v-model="inn__icontains"
                    v-debounce:400ms="myFn"
                    class="form-control"
                    placeholder="INN"
                    id="kt_datatable_search_query"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="col-md-3 my-2 my-md-0">
                <div class="input-icon">
                  <input
                    type="text"
                    v-model="phone__icontains"
                    v-debounce:400ms="myFn"
                    class="form-control"
                    placeholder="Telefon raqami"
                    id="kt_datatable_search_query"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="col-md-3 my-2 my-md-0">
                <div class="input-icon">
                  <input
                    type="text"
                    v-model="juridik_adress__icontains"
                    v-debounce:400ms="myFn"
                    class="form-control"
                    placeholder="Yuridik adres"
                    id="kt_datatable_search_query"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="col-md-3 my-2 my-md-0">
                <div class="input-icon">
                  <input
                    type="text"
                    v-model="bank__name_bank__icontains"
                    v-debounce:400ms="myFn"
                    class="form-control"
                    placeholder="Bank"
                    id="kt_datatable_search_query"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="col-md-3 my-2 my-md-0">
                <div class="input-icon">
                  <input
                    type="text"
                    v-model="bank_account__icontains"
                    v-debounce:400ms="myFn"
                    class="form-control"
                    placeholder="Bank hisob raqami"
                    id="kt_datatable_search_query"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="col-12">
                <v-btn @click="reset" color="error"
                  >{{ $t('BREADCRUMBS.CLEAN') }}
                </v-btn>
              </div>
            </div>
          </div>

          <!-- </div>
          </div> -->
        </div>
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('MENU.ENTERPRICE.CONTRAGENT.TITLE') }}
              </h3>
            </div>

            <div class="card-button">
              <v-btn color="warning mx-2" @click="filter = !filter">
                {{ $t('BREADCRUMBS.SEARCH') }}

                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn to="/kontragentscreate" class="ml-2" color="primary"
                >{{ $t('MENU.ENTERPRICE.CONTRAGENT.TITLE') }} +</v-btn
              >
            </div>
          </div>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import ContractsAction from "@/view/content/dropdown/ContractsAction.vue";

export default {
  data() {
    return {
      filter: false,
      full_name__icontains: '',
      phone__icontains: '',
      inn__icontains: '',
      juridik_adress__icontains: '',
      bank_account__icontains: '',
      bank__name_bank__icontains: ''
    }
  },
  props: {
    items: Object
  },
  created() {
    if (this.$store.state.requests.filterData.data !== undefined) {
      const data = this.$store.state.requests.filterData.data
      if (
        this.$store.state.requests.filterData.path.substring(
          0,
          this.$route.path.lastIndexOf('/')
        ) == this.$route.path.substring(0, this.$route.path.lastIndexOf('/'))
      ) {
        this.filter = true
        this.full_name__icontains = data.full_name__icontains
        this.inn__icontains = data.inn__icontains
        this.phone__icontains = data.phone__icontains
        this.juridik_adress__icontains = data.juridik_adress__icontains
        this.bank__name_bank__icontains = data.bank__name_bank__icontains
      } else {
        this.filter = false
        this.$store.commit('setFilterData', {
          data: undefined,
          path: ''
        })
      }
    }
  },
  methods: {
    reset() {
      this.full_name__icontains = ''
      this.inn__icontains = ''
      this.phone__icontains = ''
      this.juridik_adress__icontains = ''
      this.juridik_adress__icontains = ''
      this.bank__name_bank__icontains = ''
      this.bank_account__icontains = ''
      this.$store.commit('setFilterData', {
        data: undefined,
        path: ''
      })
      this.myFn()
    },
    myFn() {
      const data = {}
      data.full_name__icontains = this.full_name__icontains
      data.inn__icontains = this.inn__icontains
      data.phone__icontains = this.phone__icontains
      data.juridik_adress__icontains = this.juridik_adress__icontains
      data.bank__name_bank__icontains = this.bank__name_bank__icontains
      data.bank_account__icontains = this.bank_account__icontains
      for (let i in data) {
        if (!data[i]) delete data[i]
      }
      console.log('Before set data: ', data)
      this.$store.commit('setFilterData', {
        data: Object.keys(data).length === 0 ? undefined : data,
        path: this.$route.path
      })
      this.$store.dispatch('searchKontragentsList', { filters: data, page: 1 })
      if (this.$route.path !== '/kontragents/1') {
        this.$router.push('/kontragents/' + '1')
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.ENTERPRICE.TITLE') },
      { title: this.$t('MENU.ENTERPRICE.CONTRAGENT.TITLE') }
    ])
  }
}
</script>
